import React, { Component } from 'react';

class Education extends Component {
    render() {
        if (this.props.data) {
            var education = this.props.data.education.map(function (education) {
                return <div key={education.school}><h3>{education.school}</h3>
                    <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
                    <p>{education.description}</p>
                </div>
            })
        }

        return (
            <section id="education">
                <div className="row education">
                    <div className="three columns header-col">
                        <h1><span>Education</span></h1>
                    </div>

                    <div className="nine columns main-col">
                        {education}
                    </div>
                </div>
            </section>

        );

    }
}

export default Education;