import React, { Component } from 'react';
import TagCloud from '../components/TagCloud/index';




class Skills extends Component {


    constructor(props) {
        super(props);
        console.log("window width = " + window.innerWidth);
        // this.state = { cloudRadius: window.innerWidth * 0.12, tagPad: '150px' };
        var windowWidth = window.innerWidth;
        if(windowWidth > 1920) {
            this.state = { cloudRadius: windowWidth * 0.12, tagPad: '150px' };
        }
        else if(windowWidth <= 1920 && windowWidth >= 1367) {
            this.state = { cloudRadius: windowWidth * 0.12, tagPad: '150px' };
        }
        else if(windowWidth <= 1366 && windowWidth >= 1025) {
            this.state = { cloudRadius: windowWidth * 0.16, tagPad: '150px' };
        }
        else if(windowWidth <= 1024 && windowWidth >= 961) {
            this.state = { cloudRadius: windowWidth * 0.22, tagPad: '150px' };
        }
        else if(windowWidth <= 960 && windowWidth >= 481) {
            this.state = { cloudRadius: windowWidth * 0.22, tagPad: '150px' };
        }
        else if(windowWidth <= 480 && windowWidth >= 361) {
            this.state = { cloudRadius: windowWidth * 0.35, tagPad: '75px' };
        }
        else if(windowWidth <= 360 && windowWidth >= 321) {
            this.state = { cloudRadius: windowWidth * 0.30, tagPad: '75px' };
        }
        else {
            this.state = { cloudRadius: windowWidth * 0.25, tagPad: '75px' };
        }


        this.skillRef = React.createRef();
        
    }

    // async componentDidMount() {
    //     this.skillRef.current.style.height = `${(this.skillRef.current.offsetWidth)}px`;
    //     var str = this.skillRef.current.style.height;
    //     var newStr = str.replace("px", "");
    //     var heightNumber = parseInt(newStr);
    //     console.log(this.skillRef.current.style.height + "  " + heightNumber);
    //     heightNumber = heightNumber * 0.2;
    //     this.setState({ cloudRadius: heightNumber });
    //     window.addEventListener("resize", e => {
    //         this.skillRef.current.style.height = `${(this.skillRef.current.offsetWidth)}`;
    //         var str = this.skillRef.current.style.height;
    //         var newStr = str.replace("px", "");
    //         var heightNumber = parseInt(newStr);
    //         console.log(this.skillRef.current.style.height + "  " + heightNumber);
    //         heightNumber = heightNumber * 0.2;
    //         this.setState({ cloudRadius: heightNumber });
    //         console.log("radius = " + this.state.cloudRadius);
    //     });
    // }



    render() {

        const tagName = ['ASP.NET', 'C', 'C#', 'HTML5', 'CSS3', 'Javascript', 'Typescript', 'Git', 'MS SQL Server', 'MongoDB', 'Node.js', 'Express.js', 'React', 'Redux', 'Agile'];
        // const repeat = (tagName.join(',') + ',').repeat(2).split(',').filter(t => !!t)



        return (
            <section id="skills">

                <div className="row skill" ref={this.skillRef}>

                    <div className="three columns header-col">
                        <h1><span>Skills</span></h1>
                    </div>


                    <div style={{ margin: 'auto', width: `${this.state.cloudRadius * 3}px`, height: `${this.state.cloudRadius * 3}px`, paddingTop: this.state.tagPad}}>
                        <TagCloud tagName={tagName} radius={this.state.cloudRadius}></TagCloud>
                    </div>
                </div>


            </section>
        );
    }
}

export default Skills;
