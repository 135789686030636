import React, { Component } from 'react';

class Header extends Component {
    render() {

        if(this.props.data) {
            var name = this.props.data.name;
            var description = this.props.data.description;
        }

        return (
            <header id="home">
                <nav id="nav-wrap">
                    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                    <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

                    <ul id="nav" className="nav">
                        <li className="current"><a className="smoothscroll" href="#home">HOME</a></li>
                        <li><a className="smoothscroll" href="#about">ABOUT</a></li>
                        <li><a className="smoothscroll" href="#education">EDUCATION</a></li>
                        <li><a className="smoothscroll" href="#workxp">WORK</a></li>
                        <li><a className="smoothscroll" href="#skills">SKILLS</a></li>
                        <li><a className="smoothscroll" href="#portfolio">PROJECTS</a></li>
                        <li><a className="smoothscroll" href="#contact">CONTACT</a></li>
                    </ul>
                </nav>

                <div className="row banner">
                    <div className="banner-text">
                        <h1 className="responsive-headline">{name}</h1>
                        <h3>{description}</h3>
                    </div>
                </div>

                <p className="scrolldown">
                    <a className="smoothscroll protobuttonnn" href="#about"><i className="icon-down-circle"></i></a>
                </p>

            </header>
        );
    }
}

export default Header;