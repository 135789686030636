import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import $ from 'jquery';
import Home from './components/proton/Particles';
import About from './components/About';
import Education from './components/Education';
import Workxp from './components/Workxp';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';

const App = () => {

  const [resumeData, setResumeData] = useState({});
  const skillRef = useRef();

  const getResumeData = () => {
    $.ajax({
      url: "./resumeData.json",
      dataType: 'json',
      cache: false,
      success: function (data) {
        setResumeData(data);
      }.bind(this),
      error: function (xhr, status, err){
        console.log(err);
        alert(err);
      }
    });
  };

  useEffect(() => {
    getResumeData();
  });

  return (
    <div className="App">
      <Home data={resumeData.main} />
      <About data={resumeData.main} />
      <Education data={resumeData.resume} />
      <Workxp data={resumeData.resume} />
      <Skills ref={skillRef} data={resumeData} />
      <Projects data={resumeData.portfolio} />
      <Contact data={resumeData.main} />
      <Footer data={resumeData.main} />
    </div>

  );
}


export default App;
